import React from "react";
import BlogsCard from "../blogs-card";
import { useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { graphql, useStaticQuery } from "gatsby";
import BlogsModel from "../blogs-model";
import Text from "../../data-display/text";
import InlineLink from "../../../components/data-display/inline-link";
import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles(() => ({
  buttonPrimaryLight: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    textAlign: "center",
    borderRadius: "48px",
    padding: "10px 18px",
    cursor: "pointer",
    transition: "0.3s",
    background: "rgba(255, 255, 255,0.6)",
    border: "1.5px solid rgba(108, 82, 255,0.2)",
    "&:hover": {
      background: "rgba(255, 255, 255,1)",
    },
  },
}));

const FeaturedBlogs = () => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));

  const FeaturedBlogs = useStaticQuery(graphql`
    {
      featured: allBlogs {
        edges {
          node {
            author
            avatar
            content
            subTitle
            updateAt
            image
            slug
            title
          }
        }
      }
    }
  `);

  const blogs: BlogsModel[] =
    FeaturedBlogs?.featured?.edges?.map((el) => new BlogsModel(el.node)) ?? [];
  const classes = useStyles();

  return (
    <>
      <div style={{ height: xsDown ? "24px" : "64px" }} data-scroll={true} />
      {xsDown && (
        <div
          style={{
            scrollSnapType: "x mandatory",
            display: "flex",
            WebkitOverflowScrolling: "touch",
            overflowX: "scroll",
          }}
        >
          <div style={{ display: "-webkit-box", alignItems: "center" }}>
            {blogs.slice(0, 3).map((p) => {
              return (
                <BlogsCard
                  inView={true}
                  key={p.slug}
                  style={{
                    marginRight: "24px",
                    marginBottom: "0",
                    scrollSnapAlign: "start",
                  }}
                  smallMargin
                  title={p.title}
                  subtitle={p.subTitle}
                  image={p.image}
                  author={p.author}
                  updateAt={p.updateAt}
                  to={`/blogs/${p.slug}/`}
                />
              );
            })}
          </div>
        </div>
      )}
      {!xsDown && (
        <div style={{ display: "flex", }}>
          {blogs.slice(0, 3).map((p, index) => {
            return (
              <React.Fragment key={p.slug}>
                <BlogsCard
                  inView={true}
                  key={p.slug}
                  style={{
                    marginRight: "24px",
                    marginBottom: "0",
                    scrollSnapAlign: "start",
                  }}
                  smallMargin
                  title={p.title}
                  subtitle={p.subTitle}
                  image={p.image}
                  author={p.author}
                  updateAt={p.updateAt}
                  to={`/blogs/${p.slug}/`}
                />
                {index !== 2 && <div style={{ width: "32px" }} />}
              </React.Fragment>
            );
          })}
        </div>
      )}
      <div style={{ height: xsDown ? "0px" : "64px" }} data-scroll={true} />
      <div
        data-scroll={true}
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: xsDown ? "column" : "row",
        }}
      >
        <InlineLink
          variant={"primary"}
          to={"/blogs"}
          style={{ color: theme.palette.primary.main }}
        >
          <div
            className={classes.buttonPrimaryLight}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "200px",
              // marginLeft: smDown ? "55px" : "30px",
            }}
          >
            <Text
              selectable={false}
              variant={"body2"}
              bold
              style={{ color: theme.palette.primary.main }}
            >
              View All Blogs
            </Text>
          </div>
        </InlineLink>
      </div>
      <div style={{ height: xsDown ? "0px" : "64px" }} data-scroll={true} />
    </>
  );
};

export default FeaturedBlogs;
