import * as React from "react"
import SEO from "../components/seo/seo";
import { Box, Container, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Text from "../components/data-display/text";
import Hero from '../images/hero_home.webp';
import Wave from '../images/wave_1.webp';
import Wave2 from '../images/wave_2.png';
import Wave3 from '../images/wave_3.webp';
import ThumbnailImage from '../images/thumbnail.webp';
import PaymentImage from '../images/icon_3.png'
import Gradient1 from '../images/hero_gradients.svg'
import Gradient2 from '../images/hero_gradients_2.svg'
import SecondaryButton from "../components/buttons/secondary-button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import InlineLink from "../components/data-display/inline-link";
import { FaStar } from "react-icons/fa";
import SydetaskerVideo from "../images/sydetasker.mp4";
import ReactPlayer from "react-player";
import ModalVideo from 'react-modal-video';
import { getCategoryLink, getImage, getImagePosition, homeCategories } from "../content/category-icons";
import PlayStore from "../images/google_playstore.svg";
import SeoImage from "../images/iphone.jpg";
import AppStore from "../images/apple-appstore.svg";
import { Play } from "react-feather";
import ReviewCard from "../components/home/reviews/review-card";
import review from "../components/home/reviews/reviews.json";
import { isIOS } from "react-device-detect";
import AllServices from "../images/categories/all.jpg";
import tasks from "../components/home/task-examples/featured-tasks.json";
import { Link } from "gatsby";
import ScrollingTasks from "../components/home/task-examples/scrolling-tasks";
import { categories } from "../utils/category-icons";
import { getCategoryImage } from "../utils/category-icons";
import PostTaskWrapper from "../components/post-task/post-task-wrapper";
import { useSelector } from "react-redux";
import { RootState } from "../redux/index.reducers";
import FeaturedBlogs from "../components/blogs/homePageView/featured-blogs";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles(() => ({
  indicator: {
    backgroundColor: 'rgb(78, 62, 253)',
  },
  categoryCardContainer: {
    position: "relative",
    overflow: "hidden",
    display: 'flex',
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    cursor: "pointer",
    borderRadius: "8px",
    transition: "0.5s",
    "&:hover": {
      filter: "brightness(1.2)"
    }
  },
  categoryCardContainerLast: {
    position: "relative",
    overflow: "hidden",
    display: 'flex',
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    cursor: "pointer",
    height: "350px",
    borderRadius: "8px",
    transition: "0.5s",
    "&:hover": {
      filter: "brightness(0.95)"
    }
  },
  categoryIcon: {
    width: "120px",
    height: "110px",
    background: "white",
    borderRadius: "14px",
    border: "2px solid rgba(180,180,200,0.2)",
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
    transition: "0.4s",
    boxSizing: "border-box",
    transitionTimingFunction: "cubic-bezier(0.165, 0.84, 0.44, 1.0)",
    "&:hover": {
      transform: "scale(0.9)"
    }
  },
  buttonPrimaryLight: {
    display: "flex",
    alignItems: "center",
    alignContent:'center',
    textAlign:'center',
    borderRadius: "48px",
    padding: "10px 18px",
    cursor: "pointer",
    transition: "0.3s",
    background: "rgba(255, 255, 255,0.6)",
    border: "1.5px solid rgba(108, 82, 255,0.2)",
    "&:hover": {
      background: "rgba(255, 255, 255,1)",
    }
    // categoryIcon: {
    //   background: "white",
    //   borderRadius: "14px",
    //   boxShadow: "0 4px 12px 0 rgba(0,0,0,0.02)",
    //   position: "relative",
    //   overflow: "hidden",
    //   cursor: "pointer",
    //   transition: "0.4s",
    //   boxSizing: "border-box",
    //   transitionTimingFunction: "cubic-bezier(0.165, 0.84, 0.44, 1.0)",
    //   "&:hover": {
    //     transform: "scale(0.9)"
    //   }
    // }
  },
  categoryIconSelected: {
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "0.3s"
  },
  categoryIconCheckmark: {
    background: "white",
    width: "28px",
    height: "28px",
    borderRadius: "50%",
    transition: "0.4s",
    transitionTimingFunction: "cubic-bezier(0.165, 0.84, 0.44, 1.0)"
  }
}));

const LandingPage = () => {
 

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const [video, setVideo] = React.useState(false);
  const [tasker, setTasker] = React.useState(0);
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { isAuthenticated } = useSelector((state: RootState) => state.userData);

  return (
    <>
    
    <SEO title={'Sydetasker - Post. Hire. Done.'} pathname={"/"}    image={{ src: SeoImage }} description={'Any task you need done? Sydetasker easily connects you with trusted & experienced Taskers ready to help with home repairs, heavy lifting, yard work, & more.'} />
        <Container maxWidth={'lg'} >
          <div style={{ minHeight: isIOS ? "-webkit-fill-available" : "100vh", width: "100%", overflow: "hidden", display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <div style={{
              position: "absolute",
              left: "0", bottom: "0",
              width: "80%", height: "100%",
              background:`url(${Wave}) bottom left / contain no-repeat`,
            }}/>
            <div style={{
              position: "absolute",
              right: "0%", bottom: "0",
              width: "60%", height: "100%",
              opacity: "0.5",
              background:`url(${Gradient1}) 150% bottom / contain no-repeat`,
            }}/>
            <div style={{
              position: "absolute",
              left: "0", top: "-10%",
              width: "50%", height: "100%",
              opacity: "0.2",
              background:`url(${Gradient2}) top left / contain no-repeat`,
            }}/>
            <div style={{
              position: "absolute",
              bottom: "0", left: xsDown ? 0 : undefined, right: xsDown ? undefined : "0",
              width: "100%", height: xsDown ? "40%" : "50%",
              background: xsDown ? `url(${Hero}) -148px center / cover` : `url(${Hero}) 200% bottom / contain no-repeat`,
            }}/>
            <div style={{
              position: "absolute",
              left: "0", bottom: "0",
              width: "100%", height: "100%",
              background:`url(${Wave2}) bottom left / contain no-repeat`,
            }}/>
            <Text variant={"h1"} style={{ textAlign: "center" }} black>Let's get more done.</Text>
            <Text style={{ marginBottom: "32px", maxWidth: '600px', textAlign: "center" }} variant={"h4"}>Connect with trusted Taskers with the skills you need.</Text>
              <InlineLink to={"/tasks"}>
              <div style={{ width: "200px" }}>
              <SecondaryButton background={theme.palette.primary.main} textColor={'white'}>
                Get Started
              </SecondaryButton>
              </div>
              </InlineLink>
            <div style={{ height: "48px" }}/>
            <div style={{ display: "flex", position: "relative" }}>
              <a href={"https://play.google.com/store/apps/details?id=ai.unicode.sydetasker"}>
                <img   loading="lazy" src={PlayStore} alt={"Download Sydetasker"}/>
              </a>
              <div style={{ width: "18px" }}/>
              <a href={"https://apps.apple.com/in/app/sydetasker/id1502353211#?platform=iphone"}>
                <img  loading="lazy" src={AppStore} alt={"Download Sydetasker"}/>
              </a>
            </div>
            <div style={{ height: "30vh" }}/>
          </div>
        </Container>

          <div style={{ background: "white", paddingTop: "40px", paddingBottom: "100px" }}>
        <Container maxWidth={'lg'}>
          <div style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              <Text variant={"h2"} black> What’s your next task? </Text>
              <div style={{ height: "12px" }} />
              <Text variant={"body1"} style={{ maxWidth: '350px', margin: '0 auto', textAlign: "center" }}>
                Get help with home repairs, heavy lifting, yard work & more.
              </Text>
            </div>
            <div style={{ height: "48px" }} />
            {!xsDown &&
              <Grid container spacing={4}>
                {
                  categories().map((c) => {
                    return(
                      
                      <Grid item xs={3} sm={4} md={4} lg={2} key={c} style={{display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                      <InlineLink to={"/services/" + getCategoryLink(c)}>
                      <div
                        className={classes.categoryCardContainer}
                        style={{ margin: xsDown ? "0 16px" : "", display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" , }}
                      >
                        <div className={classes.categoryIcon} style={{ border:  "2px solid white" }}>
                          <img loading={"lazy"}  style={{ padding: "18px", width: "100%", boxSizing: "border-box" }} src={getCategoryImage(c).toString()} alt={c} />
                        </div>
                        <Text variant={"caption"} selectable={false} medium style={{ textTransform: "capitalize",marginTop: "8px" }}>
                          {c}
                        </Text>
                      </div>
                      </InlineLink>
                    </Grid>
                    )
                  })
                }
                <Grid item xs={12} sm={12} md={12} lg={12} style={{display:"flex",justifyContent:"center",marginRight:"20px",right:0,left:0,marginTop:"30px"}}>
                      <div style={{ background: "linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.25))", position: "absolute", width: "100%", height: "100%",left:0,right:0}} />
                      <InlineLink variant={"primary"} to={"/services"} style={{ color: theme.palette.primary.main }}>
                        <div className={classes.buttonPrimaryLight} style={{display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center",width:'200px',marginLeft:smDown?'55px':'30px'  }}>
                          <Text selectable={false} variant={"body2"} bold style={{ color: theme.palette.primary.main}}>
                            View All
                          </Text>
                        </div>
                      </InlineLink>
                </Grid>

              </Grid>
            }

            {xsDown &&
              <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                <div style={{ overflowX: "auto", WebkitOverflowScrolling: "touch", width: "100%" }}>
                  <div style={{ display: "-webkit-box", alignItems: "center" }}>
                  {
                  categories().map((c) => {
                    return(
                      <Grid item xs={12} sm={12} md={4} lg={2} key={c} style={{display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                      <InlineLink to={"/services/" + getCategoryLink(c)}>
                      <div
                        className={classes.categoryCardContainer}
                        style={{ margin: xsDown ? "0 16px" : "", display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" , }}
                      >
                        <div className={classes.categoryIcon} style={{ border:  "2px solid white" }}>
                          <img loading={"lazy"}  style={{ padding: "18px", width: "100%", boxSizing: "border-box" }} src={getCategoryImage(c).toString()} alt={c} />
                        </div>
                        <Text variant={"caption"} selectable={false} medium style={{ textTransform: "capitalize",marginTop: "8px" }}>
                          {c}
                        </Text>
                      </div>
                      </InlineLink>
                    </Grid>
                    )
                  })
                }
                    <Link to={"/services"} style={{ marginRight: "16px", display: "flex", width: "70vw" }}>
                      <div className={classes.categoryCardContainerLast} style={{ width: "100%", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ background: "linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.25))", position: "absolute", left: 0, right: 0, width: "100%", height: "100%" }} />
                        <InlineLink variant={"primary"} to={"/services"} style={{ color: theme.palette.primary.main }}>
                          <div className={classes.buttonPrimaryLight}>
                            <Text selectable={false} variant={"body2"} bold style={{ margin: "0 8px", color: theme.palette.primary.main }}>
                              View All
                            </Text>
                          </div>
                        </InlineLink>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            }
          </div>
        </Container>

      </div>

      
      {/* <div style={{ position: "relative", zIndex: 1, padding: "48px 0", alignItems: "center", justifyContent: "center", flexDirection: "column", display: "flex" }}>
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <Text variant={"h3"} black>See what others are getting done</Text>
          <div style={{ height: "48px" }} />
        </div>
          <Box style={{ width:"100%" }}>
          <Box style={{ display:"flex", justifyContent:"center"}}>
            <Tabs     
            classes={{
              indicator: classes.indicator
            }}
            value={value}onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Cleaning & Organizing" {...a11yProps(0)} />
              <Tab label="Extra Hands" {...a11yProps(1)} />
              <Tab label="Home Improvement" {...a11yProps(2)} />
              <Tab label="Seasonal Jobs" {...a11yProps(3)} />
              <Tab label="Anything" {...a11yProps(4)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}style={{ width:"100%" }}>
              <ScrollingTasks tasks={tasks.data.slice(0, 6)} reverse />
              <ScrollingTasks tasks={tasks.data.slice(6 ,12)} />
          </TabPanel>
          <TabPanel value={value} index={1}>
              <ScrollingTasks tasks={tasks.data.slice(12,18 )} reverse />
              <ScrollingTasks tasks={tasks.data.slice(18 ,24)} />
          </TabPanel>
          <TabPanel value={value} index={2}>
             <ScrollingTasks tasks={tasks.data.slice(24,30 )} reverse />
              <ScrollingTasks tasks={tasks.data.slice(30 ,36)} />
          </TabPanel>
          <TabPanel value={value} index={3}>
              <ScrollingTasks tasks={tasks.data.slice(36,42 )} reverse />
              <ScrollingTasks tasks={tasks.data.slice(42 ,48)} />
          </TabPanel>
          <TabPanel value={value} index={4}>
              <ScrollingTasks tasks={tasks.data.slice(48,54)} reverse />
              <ScrollingTasks tasks={tasks.data.slice(54,60)} />
          </TabPanel>
        </Box>
        */}
        {/* <div style={{ width: "200px", marginTop: "24px" }}>
          <SecondaryButton to={"/login"} background={theme.palette.primary.main} textColor={'white'}>
            Post a Task
          </SecondaryButton>
        </div> */}
      {/* </div> */}

      <div style={{ position: "relative", zIndex: 1, padding: "48px 0", alignItems: "center", justifyContent: "center", flexDirection: "column", display: "flex" }}>
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <Text variant={"h2"} black>Get Anything Done on Sydetasker</Text>
          <div style={{ height: "48px" }} />
        </div>
        {/* <ScrollingTasks tasks={tasks.data.slice(0, 8)} reverse />
        <ScrollingTasks tasks={tasks.data.slice(8)} /> */}
         {xsDown? <Box style={{ width:"100%" }}>
              <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                <div style={{ overflowX: "auto", WebkitOverflowScrolling: "touch", width: "100%" }}>
                  <div style={{ display: "-webkit-box", alignItems: "center" }}>
                  <Tabs     
            classes={{
              indicator: classes.indicator
            }}
            value={value}onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Cleaning & Organizing" {...a11yProps(0)} />
              <Tab label="Extra Hands" {...a11yProps(1)} />
              <Tab label="Home Improvement" {...a11yProps(2)} />
            </Tabs>
            <Tabs     
            classes={{
              indicator: classes.indicator
            }}
            value={value}onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Seasonal Jobs" {...a11yProps(3)} />
              <Tab label="Anything" {...a11yProps(4)} />
            </Tabs>
                   
                  </div>
                </div>
              </div>
          <TabPanel value={value} index={0}style={{ width:"100%" }}>
              <ScrollingTasks tasks={tasks.data.slice(0, 6)} reverse />
              <ScrollingTasks tasks={tasks.data.slice(6 ,12)} />
          </TabPanel>
          <TabPanel value={value} index={1}>
              <ScrollingTasks tasks={tasks.data.slice(12,18 )} reverse />
              <ScrollingTasks tasks={tasks.data.slice(18 ,24)} />
          </TabPanel>
          <TabPanel value={value} index={2}>
             <ScrollingTasks tasks={tasks.data.slice(24,30 )} reverse />
              <ScrollingTasks tasks={tasks.data.slice(30 ,36)} />
          </TabPanel>
          <TabPanel value={value} index={3}>
              <ScrollingTasks tasks={tasks.data.slice(36,42 )} reverse />
              <ScrollingTasks tasks={tasks.data.slice(42 ,48)} />
          </TabPanel>
          <TabPanel value={value} index={4}>
              <ScrollingTasks tasks={tasks.data.slice(48,54)} reverse />
              <ScrollingTasks tasks={tasks.data.slice(54,60)} />
          </TabPanel>
        </Box>:
         <Box style={{ width:"100%" }}>
         <Box style={{ display:"flex", justifyContent:"center"}}>
           <Tabs     
           classes={{
             indicator: classes.indicator
           }}
           value={value}onChange={handleChange} aria-label="basic tabs example">
             <Tab label="Cleaning & Organizing" {...a11yProps(0)} />
             <Tab label="Extra Hands" {...a11yProps(1)} />
             <Tab label="Home Improvement" {...a11yProps(2)} />
             <Tab label="Seasonal Jobs" {...a11yProps(3)} />
             <Tab label="Anything" {...a11yProps(4)} style={{paddingRight:"40px"}} />
           </Tabs>
         </Box>
         <TabPanel value={value} index={0}style={{ width:"100%" }}>
             <ScrollingTasks  tasks={tasks.data.slice(0, 6)} reverse />
             <ScrollingTasks tasks={tasks.data.slice(6 ,12)} />
         </TabPanel>
         <TabPanel value={value} index={1}>
             <ScrollingTasks tasks={tasks.data.slice(12,18 )} reverse />
             <ScrollingTasks tasks={tasks.data.slice(18 ,24)} />
         </TabPanel>
         <TabPanel value={value} index={2}>
            <ScrollingTasks tasks={tasks.data.slice(24,30 )} reverse />
             <ScrollingTasks tasks={tasks.data.slice(30 ,36)} />
         </TabPanel>
         <TabPanel value={value} index={3}>
             <ScrollingTasks tasks={tasks.data.slice(36,42 )} reverse />
             <ScrollingTasks tasks={tasks.data.slice(42 ,48)} />
         </TabPanel>
         <TabPanel value={value} index={4}>
             <ScrollingTasks tasks={tasks.data.slice(48,54)} reverse />
             <ScrollingTasks tasks={tasks.data.slice(54,60)} />
         </TabPanel>
       </Box>
        }
      
      {isAuthenticated && 
      <PostTaskWrapper>
      <div style={{ width: "200px", marginTop: "24px" }}>
          <SecondaryButton  background={theme.palette.primary.main} textColor={'white'}>
            Post a Task
          </SecondaryButton>
        </div>
        </PostTaskWrapper> 
         }

     {!isAuthenticated && 
      <PostTaskWrapper>
      <div style={{ width: "200px", marginTop: "24px" }}>
          <SecondaryButton to="/login" background={theme.palette.primary.main} textColor={'white'}>
            Post a Task
          </SecondaryButton>
        </div>
        </PostTaskWrapper> 
         }
        </div>

      <div style={{ position: "relative", alignItems: "center", justifyContent: "center", flexDirection: "column", display: "flex", paddingTop: "64px" }}>
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <Text variant={"h2"} black>How Sydetasker Works</Text>
          <div style={{ height: "12px" }} />
          <Text variant={"body1"} style={{ maxWidth: '550px', margin: '0 auto', textAlign: "center" }}>
            Watch the video below to understand how Sydetasker can help get your tasks done easily.
          </Text>
        </div>
        <div style={{ height: "32px" }} />


        {<Grid container direction="row" justify={"center"}>
          <Grid item md={6} sm={6} xs={11} style={{ display: "flex", alignItems: "center", justifyContent: "center", }} >
            <ReactPlayer
              url={SydetaskerVideo}
              playIcon={
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%", background: 'rgba(0,0,0,0.2)' }}>
                  <div style={{ background: theme.palette.primary.main, borderRadius: "50%", width: '64px', height: '64px', alignItems: "center", justifyContent: "center", display: "flex" }}>
                    <Play size={28} style={{ transform: "translateX(2px)", width: "28px" }} color='white' />
                  </div>
                </div>
              }
              light={ThumbnailImage}
              controls={true} playing={true}
            />
          </Grid>
        </Grid>}
        <div style={{ margin: xsDown ? '18px' : '48px 18px', maxWidth: "900px" }}>
          <Grid container direction="row" justify={"space-between"} spacing={xsDown ? 2 : 4}>
            <Grid item md={6} sm={6} xs={11} >
              <div style={{ background: xsDown ? "linear-gradient(rgb(245, 244, 247), rgba(247, 248, 251, 0))" : "", borderRadius: "12px" }}>
                <Grid container direction='row' justify={xsDown ? 'space-between' : 'flex-start'} alignItems={'flex-start'} style={{ padding: "16px 10px" }}>
                  <Grid item xs={4} md={4}>
                    <img
                      loading={"lazy"} 
                      alt={"Post a Task"}
                      src={"https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Ficon_1.webp?alt=media&token=daa3a1cd-b39e-4fc1-9dae-0f11814b3fda"}
                      height={xsDown ? "80px" : "100px"}
                    />
                  </Grid>
                  <Grid item xs={7} md={6}>
                    <Grid container direction='column'>
                      <Grid item md={12}>
                        <div style={{ display: "flex" }}>
                          {!xsDown &&
                            <div style={{ width: "22px", height: "22px", position: "absolute", background: `linear-gradient(181.81deg,#6333ff 1.51%,#441ebf 98.47%)`, transform: "translateX(-28px)", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <Text variant={'caption'} component={'p'} style={{ color: "white", transform: "translateX(-0.5px)" }} bold>1</Text>
                            </div>
                          }
                          <Text style={{ marginBottom: '8px' }} variant={'body2'} bold component={'p'}>Post a Task</Text>
                        </div>
                      </Grid>
                      <Grid item md={12}>
                        <Text style={{ width: xsDown ? "100%" : '80%', marginBottom: "60px" }} variant={'body2'} component={'p'} regular>Describe your task & post it. (It's free)</Text>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item md={6} sm={6} xs={11} >
              <div style={{ borderRadius: "12px" }}>
                <Grid container direction='row' justify={xsDown ? 'space-between' : 'flex-start'} alignItems='flex-start' style={{ padding: "16px 10px" }}>
                  <Grid item xs={4} md={4}>
                    <img
                      loading={"lazy"} 
                      alt={"Review Offers"}
                      src={'https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Ficon_2.webp?alt=media&token=85263787-a6f3-4c79-95c0-dc0589ec6542'}
                      height={xsDown ? "80px" : "100px"}
                    />
                  </Grid>
                  <Grid item xs={7} md={6}>
                    <Grid container direction='column' >
                      <Grid item md={12}>
                        <div style={{ display: "flex" }}>
                          {!xsDown &&
                            <div style={{ width: "22px", height: "22px", position: "absolute", background: `linear-gradient(181.81deg,#6333ff 1.51%,#441ebf 98.47%)`, transform: "translateX(-28px)", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <Text variant={'caption'} component={'p'} style={{ color: "white", transform: "translateX(-0.5px)" }} bold>2</Text>
                            </div>
                          }
                          <Text style={{ marginBottom: '8px' }} variant={'body2'} bold component={'p'}>Review Offers</Text>
                        </div>
                      </Grid>
                      <Grid item md={12}>
                        <Text style={{ marginBottom: "60px" }} variant={'body2'} regular>View offers from local Taskers and accept your top choice.</Text>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item md={6} sm={6} xs={11} >
              <div style={{ borderRadius: "12px" }}>
                <Grid container direction='row' justify={xsDown ? 'space-between' : 'flex-start'} alignItems={'flex-start'} style={{ padding: "16px 10px" }}>
                  <Grid item xs={4} md={4}>
                    <img
                      loading={"lazy"} 
                      alt={"Easy Payment"}
                      src={PaymentImage}
                      height={xsDown ? "80px" : "100px"}
                    />
                  </Grid>
                  <Grid item xs={7} md={6}>
                    <Grid container direction='column' >
                      <Grid item md={12}>
                        <div style={{ display: "flex" }}>
                          {!xsDown &&
                            <div style={{ width: "22px", height: "22px", position: "absolute", background: `linear-gradient(181.81deg,#6333ff 1.51%,#441ebf 98.47%)`, transform: "translateX(-28px)", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <Text variant={'caption'} component={'p'} style={{ color: "white", transform: "translateX(-0.5px)" }} bold>3</Text>
                            </div>
                          }
                          <Text style={{ marginBottom: '8px' }} variant={'body2'} bold component={'p'}>Easy Payment</Text>
                        </div>
                      </Grid>
                      <Grid item md={12}>
                        <Text style={{ marginBottom: "60px" }} variant={'body2'} regular>Payment can be hourly or flat rate, secure & simple.</Text>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item md={6} sm={6} xs={11} >
              <div style={{ borderRadius: "12px" }}>
                <Grid container direction='row' justify={xsDown ? 'space-between' : 'flex-start'} alignItems='flex-start' style={{ padding: "16px 10px" }}>
                  <Grid item xs={4} md={4}>
                    <img
                      loading={"lazy"} 
                      alt={"Complete Task"}
                      src={'https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Ficon_4.png?alt=media&token=6d60a209-5832-4207-a427-b3acec26ac8a'}
                      height={xsDown ? "80px" : "100px"}
                    />
                  </Grid>
                  <Grid item xs={7} md={6}>
                    <Grid container direction='column'>
                      <Grid item md={12}>
                        <div style={{ display: "flex" }}>
                          {!xsDown &&
                            <div style={{ width: "22px", height: "22px", position: "absolute", background: `linear-gradient(181.81deg,#6333ff 1.51%,#441ebf 98.47%)`, transform: "translateX(-28px)", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <Text variant={'caption'} component={'p'} style={{ color: "white", transform: "translateX(-0.5px)" }} bold>4</Text>
                            </div>
                          }
                          <Text style={{ marginBottom: '8px' }} variant={'body2'} bold component={'p'}>Complete task</Text>
                        </div>
                      </Grid>
                      <Grid item md={12}>
                        <Text style={{ marginBottom: "60px" }} variant={'body2'} component={'p'} regular>Discuss details with your tasker & get it done.</Text>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ width: "100%", height: "100%" }}>
        <img  loading="lazy" src={'https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Fwave_3.webp?alt=media&token=124593b7-46fe-47e2-ae96-62b0f73018db'} width={"100%"} alt={"background"} />
      </div>

      <div style={{ position: "relative", zIndex: 1, transform: "translateY(-4px)", padding: "48px 0", background: "#F1FEF4", alignItems: "center", justifyContent: "center", flexDirection: "column", display: "flex" }}>

        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <Text variant={"h2"} black>Featured Taskers</Text>
          <div style={{ height: "12px" }} />
          {/*<div style={{ height: xsDown ? "24px" : "48px" }}/>*/}
          <div style={{ display: 'flex' }}>
            <div style={{ cursor: "pointer" }} onClick={() => { setTasker(0) }}>
              <Text variant={"h6"} bold style={{ color: tasker === 0 ? theme.palette.primary.main : "grey", margin: "0 8px" }}>
                Turner
              </Text>
            </div>
            <div style={{ cursor: "pointer" }} onClick={() => { setTasker(1) }}>
              <Text variant={"h6"} bold style={{ color: tasker === 1 ? theme.palette.primary.main : "grey", margin: "0 8px" }}>
                Ali
              </Text>
            </div>
            <div style={{ cursor: "pointer" }} onClick={() => { setTasker(2) }}>
              <Text variant={"h6"} bold style={{ color: tasker === 2 ? theme.palette.primary.main : "grey", margin: "0 8px" }}>
                Rocky
              </Text>
            </div>
          </div>
          <div style={{ height: "12px" }} />
        </div>
        <div style={{ margin: '16px 0px', maxWidth: "960px" }}>
          <div style={{ overflow: "hidden" }}>
            <Grid container direction="row" justify="center">
              <Grid item xl={5} lg={4} md={4} sm={4} xs={12} >
                <div style={{ position: "relative" }}>
                  <div style={{ height: "550px", overflow: "hidden" }}>
                    {tasker === 0 ? <img  loading="lazy" style={{ width: "100%" }} src={'https://ik.imagekit.io/wffo0jsx3/syde-dev/o/taskers%2Fturner.webp?alt=media&token=69a8b8dd-9bbe-47c1-8491-28d9f4f60905'} alt={"Turner - Featured Tasker on Sydetasker"} /> : ""}
                    {tasker === 1 ? <img  loading="lazy" style={{ width: "100%" }} src={'https://ik.imagekit.io/wffo0jsx3/syde-dev/o/taskers%2Fali.webp?alt=media&token=5ed70a53-c069-4094-875b-9aa566f151bf'} alt={"Ali - Featured Tasker on Sydetasker"} /> : ""}
                    {tasker === 2 ? <img  loading="lazy" style={{ width: "100%" }} src={'https://ik.imagekit.io/wffo0jsx3/syde-dev/o/taskers%2Frocky.webp?alt=media&token=baa3ab9d-9378-46b3-8d05-24ae9a18ba96'} alt={"Rocky - Featured Tasker on Sydetasker"} /> : ""}
                    <div style={{
                      position: "absolute",
                      left: "24px", top: "24px",
                      background: "white",
                      border: "1.5px solid rgba(0,0,0,0.1)",
                      padding: "8px 16px",
                      borderRadius: "59px",
                      marginRight: "12px",
                      marginBottom: "12px"
                    }}>
                      <div style={{ display: "flex" }}>
                        {
                          [0, 1, 2, 3, 4].map((i) => {
                            return <FaStar key={i} color={"rgb(255, 186, 0)"} style={{ width: `${20}px`, height: `${20}px`, marginRight: "3px" }} />
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xl={7} lg={7} md={7} sm={8} xs={12} >
                <div style={{ padding: "0 32px" }}>
                  <div style={{ height: "18px" }} />
                  <div style={{ display: "flex" }}>
                    <Text bold variant={"h6"} style={{}}>
                      Hi! I'm {tasker === 0 && "Turner"} {tasker === 1 && "Ali"} {tasker === 2 && "Rocky"}<span style={{ marginLeft: "12px" }} />👋
                    </Text>
                  </div>
                  <div style={{ height: "18px" }} />
                  <Text regular variant={"h6"} style={{ width: "88%" }}>
                    {tasker === 0 ? "By day, Turner is busy working & learning how to be an electrician. However, he enjoys spending some of his free afternoons doing side jobs on Sydetasker for a supplemental income. He has been a Tasker since 2019. " : ""}
                    {tasker === 1 ? "Ali has a full-time job in Milwaukee working for a non-profit program called “Respect Our Waters”. When she isn’t helping our environment, she is helping others get more done on Sydetasker." : ""}
                    {tasker === 2 ? "As an independent business owner, Rocky is the ultimate handyman who picks up extra jobs on Sydetasker. He has his own insurance, can complete tasks from a full bathroom remodel to a single door knob fix, & has been a Tasker since early 2020. \n" : ""}
                  </Text>
                  <div style={{ height: "18px" }} />
                  <Text variant={"overline"}>
                    SKILLS
                  </Text>
                  <div style={{ height: "6px" }} />
                  <div style={{ display: "flex", flexWrap: "wrap", width: "80%" }}>
                    {
                      tasker === 0 &&
                      ["Furniture Assembly", "Heavy Lifting", "General Yardwork"].map((s) => {
                        return <div                           key={s}
                           style={{
                          background: "teal",
                          border: "1.5px solid rgba(0,0,0,0.1)",
                          padding: "4px 16px",
                          borderRadius: "59px",
                          marginRight: "12px",
                          marginBottom: "12px"
                        }}>
                          <Text medium variant={"body2"} style={{ color: "white" }}>
                            {s}
                          </Text>
                        </div>
                      })
                    }
                    {
                      tasker === 1 &&
                      ["Yardwork", "House Cleaning", "Pet Sitting", "Administrative Tasks"].map((s) => {
                        return <div style={{
                          background: "teal",
                          border: "1.5px solid rgba(0,0,0,0.1)",
                          padding: "4px 16px",
                          borderRadius: "59px",
                          marginRight: "12px",
                          marginBottom: "12px"
                        }}>
                          <Text medium variant={"body2"} style={{ color: "white" }}>
                            {s}
                          </Text>
                        </div>
                      })
                    }
                    {
                      tasker === 2 &&
                      ["Handyman", "Carpentry", "Construction, Hauling"].map((s) => {
                        return <div style={{
                          background: "teal",
                          border: "1.5px solid rgba(0,0,0,0.1)",
                          padding: "4px 16px",
                          borderRadius: "59px",
                          marginRight: "12px",
                          marginBottom: "12px"
                        }}>
                          <Text medium variant={"body2"} style={{ color: "white" }}>
                            {s}
                          </Text>
                        </div>
                      })
                    }
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "relative",
          zIndex: 1,
          padding: "48px 0",
          background: "white",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          display: "flex",
        }}
      >
          <Text variant={"h2"} black>
            Our Blogs
          </Text>
       
      <FeaturedBlogs/>
      </div>

      <div style={{ position: "relative", zIndex: 1, padding: "48px 0", alignItems: "center", justifyContent: "center", flexDirection: "column", display: "flex" }}>
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <Text variant={"h2"} black>Reviews From Our Customers</Text>
          <div style={{ height: "48px" }} />
        </div>
        <Container maxWidth={"lg"}>
          <Grid container spacing={xsDown ? 0 : 5}>
            {
              (!xsDown ? review : review.slice(0, 3)).map((r) => {
                return (
                  <Grid item key={r.name} xs={12} sm={12} md={4} lg={4} xl={4}>
                    <ReviewCard review={r} />
                  </Grid>
                )
              })
            }
          </Grid>
        </Container>
      </div>
      {/*<DialogWrapper maxWidth={'md'} height={'unset'} root onClose={() => { setVideo(false) }} open={video}>*/}
      {/*  <ReactPlayer*/}
      {/*      url={SydetaskerVideo}*/}
      {/*      style={{ width: "100%", padding: "12px 0" }}*/}
      {/*      className='react-player'*/}
      {/*      // playIcon={<div style={{ background: theme.palette.primary.main, borderRadius: "50%", width: '64px', height: '64px', alignItems: "center", justifyContent: "center", display: "flex" }}><Play size={28} style={{ transform: "translateX(2px)", width: "28px" }} color='white' /></div>}*/}
      {/*      // light={ThumbnailImage}*/}
      {/*      controls={true} playing={true}*/}
      {/*  />*/}
      {/*</DialogWrapper>*/}
    </>
  );
};
// const App = ({transitionStatus})=>{
//   const location =useLocation();
 
//   return(
//     <div>
//       <div>
//         <Router location={location}>
//         <NoAuthRoute path="/" component={LandingPage} transitionStatus={transitionStatus} redirect={true}/>

//         </Router>
//         </div>
//     </div>
//   )
  

// }

export default LandingPage;